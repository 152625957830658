<template>
  <div>
    <br><br>
    <Row type="flex" justify="center" :gutter="5">
      <Col :xs="19" :sm="14" :md="14" :lg="14">
        <Card>
          <p slot="title">
            <Icon type="md-radio"/>
            我的电台
          </p>
          <p>
            我是一直有着几个梦想的，其中一个梦想就是做一名主持人。<br/>
            无论是舞台上的主持人，还是电台里的主持人，我都是喜欢的。<br/>
            然而稍微有点点尴尬的是普通话和音色都不好，达不到做一个专业主持人的标准。<br/>
            所以呢，就在这里，用这样的一种形式，做着自己喜欢做的事情，用声音传达、阐述着自己的心情。<br/>

            十分青涩的声音,充满了回忆,请戴上耳机。
          </p>

          <aplayer :music="music" :list="musics"></aplayer>
        </Card>
      </Col>
      <Col :xs="19" :sm="5" :md="5" :lg="5">
        <Card>
          <p slot="title">
            <Icon type="md-medal"/>
            ABOUT ME
          </p>
          <img src="https://files.xsdhy.com/public/left-xsdhy-01.jpg" width="100%" height="100%" alt="about me">
          <p>
            <b>Name</b>唐川<br>
            <b>Motto</b><br>不积跬步无以至千里<br>不积小流无以成江海。<br>
            <b>Concept</b><br>我希望能够改变这个世界，或者说在人们改变世界的潮流中能够有自己的参与。<br>
            <b>CONNECT ME</b><br>QQ:402837074<br>weChat:tcxsdhy
          </p>
        </Card>
      </Col>
    </Row>
  </div>
</template>


<script>
import Aplayer from 'vue-aplayer'

export default {
  components: {
    Aplayer
  },
  data() {
    return {
      music: {},
      musics: [
        {
          title: '梦未老',
          artist: '2015-02-13.消逝的红叶',
          url: 'https://files.xsdhy.com/ting/mwl.mp3',
          pic: 'https://files.xsdhy.com/ting/mwl.jpg'
        },
        {
          title: '如果再见是为了留念',
          artist: '2016-07-15.消逝的红叶',
          url: 'https://files.xsdhy.com/ting/rgzjswlll.mp3',
          pic: 'https://files.xsdhy.com/ting/rgzjswlll.jpg'
        }
      ]
    }
  },
  created() {
    document.title = "ting - 消逝的红叶-我就是我，是不一样的烟火";
    this.music = this.musics[0];
  },
  methods: {}
}
</script>
